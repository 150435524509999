import { Link } from "react-router-dom";
import { useGameContext } from "../context/useGameContext";
import ButtonPlay from "./ButtonPlay";

export const GameInstructionsModal = ({
  instructions,
  toggleInstructions,
}: any) => {
  const { isGameRunning } = useGameContext();
  return (
    <div className={`modal ${instructions ? "is-active" : ""}`}>
      <div className="modal-background" onClick={toggleInstructions}></div>
      <div className="modal-content instructions has-text-white has-text-left font-antonio">
        <p className="title has-text-white font-anton ">Instrukcja gry</p>

        <p className="is-size-5">
          1. Rozejrzyj się dookoła, wiesz gdzie jest to miejsce, które widzisz?
        </p>
        <p className="is-size-5">
          2. Powiększ mapę świata, używaj przybliżenia na mapie świata, aby
          precyzyjnie określić lokalizacje
        </p>
        <p className="is-size-5">
          3. Możesz zaznaczyć dowolną ilość miejsc na mapie świata
        </p>
        {isGameRunning ? (
          <Link to="/lobby">
            <ButtonPlay title="Wejdź do gry" />
          </Link>
        ) : (
          <></>
        )}
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={toggleInstructions}
      ></button>
    </div>
  );
};
