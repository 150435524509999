import { useGameContext } from "../context/useGameContext";

export const InstructionsElement = () => {
  const { gameSetup } = useGameContext();
  return (
    <div>
      <div>
        <h1 className="title is-3 font-anton">Jak działa konkurs?</h1>
        <p className="subtitle">Nie dało się prościej!</p>
      </div>
      <section className="section columns">
        <div className="column">
          <div className=" columns ">
            <div className="column ">
              <p className="title is-4 has-text-centered number mainPage">1</p>
            </div>
            <div className="column is-four-fifths">
              <p className="subtitle is-5 has-text-left">
                Znajdź miejsce, a którym się znajdujesz
              </p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className=" columns ">
            <div className="column ">
              <p className="title is-4 has-text-centered number mainPage">2</p>
            </div>
            <div className="column is-four-fifths">
              <p className="subtitle is-5 has-text-left">
                Zaznacz to miejsce na mapie świata, możesz kilka miejsc
              </p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className=" columns ">
            <div className="column ">
              <p className="title is-4 has-text-centered number mainPage">3</p>
            </div>
            <div className="column is-four-fifths">
              <p className="subtitle is-5 has-text-left">
                Wygraj Samsung A55 i wspieraj bezdomne zwierzaki
              </p>
            </div>
          </div>
        </div>
      </section>
      <p className="text-size-is-4 m-5">
        <strong>Uwaga:</strong> wpisowe uprawniające do udziału w konkursie jest
        płatne, jedno zaznaczenie kosztuje zawrotną kwotę
        <strong className="has-text-weight-bold">
          {" "}
          {gameSetup.price} PLN{" "}
        </strong>
        za każde zaznaczenie. Większośc środków z konkursu przeznaczone zostanie
        na schroniska dla bezdomnych zwierząt ⋆˚🐾˖°
      </p>
    </div>
  );
};
