import { useNavigate } from "react-router-dom";
import { apiMongo } from "../../api/apiData";
import { useAuthContext } from "../../context/useAuthContext";
import { useEffect, useState } from "react";

export const AdminLogin = () => {
  const navigate = useNavigate();

  const { userInfo, setUserInfo } = useAuthContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userInfo) {
      navigate("/adminDashboard");
    }
  }, [userInfo, navigate]);

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response = await apiMongo.post(
        `${process.env.REACT_APP_MONGO_URL}/admin/auth`,
        {
          email,
          password,
        }
      );
      if (response.data) {
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        setUserInfo(response.data);
      }
    } catch (error: any) {
      console.log("error", error.response);
    }
  };

  return (
    <div className="section">
      <br />
      <br />
      <br />
      <p className="title">Admin Login Page</p>
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input
              className="input is-info"
              type="text"
              placeholder="Email"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="field">
          <label className="label">Password</label>
          <div className="control">
            <input
              className="input is-info"
              type="text"
              placeholder="Password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
        </div>
        <button type="submit" className="button is-primary">
          Log in
        </button>
      </form>
    </div>
  );
};
