import { Link } from "react-router-dom";
import ButtonPlay from "../components/ButtonPlay";
import { CookiesModal } from "../components/CookiesModal";
import { CookiesModalPreferences } from "../components/CookiesModalPreferences";
import ReactPlayer from "react-player/youtube";
import { useGameContext } from "../context/useGameContext";

export const LobbyPage = () => {
  const { gameSetup } = useGameContext();
  return (
    <>
      <div className=" lobbyPage">
        <p className="title font-anton has-text-weight-light pt-5">
          Instrukcja jak wziąć udział
        </p>
        <p className=" is-uppercase font-antonio is-4">Prościej się nie dało</p>
        <section className="section">
          <div className="numeration-text">
            <strong className="has-text-weight-bold number">1</strong>
            <p className="title is-5 has-text-left has-text-weight-light">
              Dołącz do gry i rozejrzyj się dookoła gdzie się znajdujesz
              (obracaj się dookoła przytrzymując i przesujawąc widok).
            </p>
          </div>
          <div className="numeration-text">
            <strong className="has-text-weight-bold number">2</strong>
            <p className="title is-5 has-text-left has-text-weight-light">
              Nestepnie otwórz mapę świata, klikając na nią i zaznacz na to
              miejsce. Przybliż widok, aby precyzyjnie zaznaczyć miejsce. Jeśli
              nie masz pewności, możesz zaznaczyć parę miejsc.
            </p>
          </div>
          <div className="numeration-text">
            <strong className="has-text-weight-bold number">3</strong>
            <p className="title is-5 has-text-left has-text-weight-light">
              Jesteś najbliżej miejsca, wygrywasz
              <strong className="has-text-weight-bold"> Samsung A55</strong>
            </p>
          </div>
          <div className="numeration-text">
            <p className="title is-5 has-text-left has-text-weight-light instruction-text mt-5">
              <strong className="has-text-weight-bold">Uwaga:</strong> wpisowe
              uprawniające do udziału w konkursie jest płatne, jedno zaznaczenie
              kosztuje zawrotną kwotę
              <strong className="has-text-weight-bold">
                {" "}
                {gameSetup.price} PLN{" "}
              </strong>
              za każde zaznaczenie. Większośc środków z konkursu przeznaczone
              zostanie na schroniska dla bezdomnych zwierząt ⋆˚🐾˖°. Bądź na
              bieżąco sledząc nasze sociale!
            </p>
          </div>
        </section>
        <div className="mb-5">
          <nav className="level">
            <div className="level-item has-text-centered">
              <Link to="/game">
                <ButtonPlay title="Zaczynamy!" />
              </Link>
            </div>
          </nav>
        </div>

        {/* <section className="section">
          <ReactPlayer
            controls={true}
            playing={false}
            url="https://youtu.be/tNdONNZ7SUs"
            width="auto"
          />
        </section> */}
        <CookiesModal />
        <CookiesModalPreferences />
      </div>
    </>
  );
};
