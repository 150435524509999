import { Link, useLocation } from "react-router-dom";
import ButtonPlay from "../components/ButtonPlay";
import { useGameContext } from "../context/useGameContext";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
moment.locale("pl");

export const FinalPage = () => {
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  const { gameSetup } = useGameContext();

  useEffect(() => {
    // Odczytaj parametry z URL
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const paymentId = searchParams.get("paymentId");

    // Ustaw status płatności na podstawie parametrów z URL
    if (status === "success") {
      setPaymentStatus("success");
      // Możesz też wysłać zapytanie do swojego backendu w celu weryfikacji płatności

      // zmien status zamówienia na paid, dodaj paidAt itd.

      // wyślij potwierdzenie płatności
      // await sendTransactionConfirmation(orderData._id);
    } else {
      setPaymentStatus("failure");
    }
  }, [location]);

  const initialEndDate = new Date(gameSetup.endDate);

  const endDate = moment(initialEndDate)
    .tz("Europe/Warsaw")
    .locale("pl")
    .format("D MMMM YYYY HH:mm");

  return (
    <div className="heroNotGame">
      <section className="section">
        <p className="title is-3 font-anton mb-6">
          Dzięki za udział z zabawie!
        </p>
        <p className="subtitle is-5">Twoja płatność przeszła pomyślnie!</p>

        <div className="subtitle box">
          <p className="mb-2">Aktualny konkurs kończy się:</p>
          <p className="has-text-weight-semibold">{endDate}</p>
        </div>

        <div className="subtitle box">
          <p className="mb-2">
            Info o zwycięzcy wyślemy do 3 dni po zakończeniu konkursu!
          </p>
        </div>

        <p className="">
          Info o wsparciu dla schroniska będziemy publikować na naszych
          socialach.
        </p>
        <p>Obserwuj nasze sociale LINK DO INSTA MAPGAME</p>
        <div className="button-box-finalPage">
          <Link to="/">
            <button className="button is-primary">Wróć do głównej</button>
          </Link>
          <Link to="/game">
            <ButtonPlay title="Zagraj jeszcze raz" />
          </Link>
        </div>
      </section>
    </div>
  );
};
