import { z } from "zod";

const polishLettersRegex = /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/;

export const zodCartSchema = z.object({
  firstName: z
    .string()
    .min(2, { message: "Masz niespotykanie krótkie imię" })
    .max(20, { message: "Masz niespotykanie długie imię" })
    .regex(polishLettersRegex, "To nie jest imię"),
  lastName: z
    .string()
    .min(2, { message: "Masz niespotykanie krótkie nazwisko" })
    .max(35, { message: "Masz niespotykanie długie nazwisko" })
    .regex(polishLettersRegex, "Niepoprawny format"),
  email: z.string().email({ message: "Niepoprawny format maila" }),
  phone: z
    .string()
    .regex(/^[0-9\+]{8,13}$/, { message: "Niepoprawny format telefonu" }),
  city: z.string().optional(),
  promoCode: z.string().optional(),
  paymentMethod: z
    .string()
    .refine((value) => value === "przelewy24" || value === "blik", {
      message: "Wybierz metodę płatności",
    }),
  purchaseAgreements: z.boolean().refine((value) => value === true, {
    message: "Zgoda jest wymagana",
  }),
});

export const zodNewsletterSchema = z.object({
  userFirstName: z.string().optional(),
  userEmail: z.string().email({ message: "Niepoprawny format maila" }),
});
