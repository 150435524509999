import { Link } from "react-router-dom";

export const CartForm = ({ register, errors, openSummary }: any) => {
  return (
    <>
      <form className="box" id="hook-form">
        <p className="title is-5 font-antonio">
          <strong>Metoda płatności</strong>
        </p>
        <div className="box payment-box">
          <p>Chwilowo brak metody płatności do wyboru</p>
          {/* <div className="field">
            <input
              {...register("paymentMethod")}
              type="radio"
              value="blik"
              className="is-checkradio"
              id="blik"
            />
            <label htmlFor="blik">Blik</label>
          </div>

          <div className="field">
            <input
              {...register("paymentMethod")}
              type="radio"
              value="przelewy24"
              className="is-checkradio"
              id="przelewy24"
            />
            <label className="radio" htmlFor="przelewy24">
              Przelewy24
            </label>
          </div> */}

          {errors.paymentMethod && (
            <p className="subtitle is-7 has-text-danger is-italic">
              {errors.paymentMethod?.message}
            </p>
          )}
        </div>

        <p className="title is-5 font-antonio">
          <strong>Dane kontaktowe</strong>
        </p>
        <p>Potrzebujemy parę informacji, aby miec z Tobą kontakt jak wygrasz</p>
        <br />
        <div className="columns ">
          <div className="column">
            <div className="field">
              <label className="label font-antonio">Imię*</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  className="input"
                  placeholder="Imię"
                  {...register("firstName")}
                />
                {errors.firstName && (
                  <p className="subtitle is-7 has-text-danger is-italic">
                    {errors.firstName?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label font-antonio">Nazwisko*</label>
              <div className="control has-icons-left">
                <input
                  type="text"
                  className="input"
                  placeholder="Nazwisko"
                  {...register("lastName")}
                />
                {errors.lastName && (
                  <p className="subtitle is-7 has-text-danger is-italic">
                    {errors.lastName?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label font-antonio">Email*</label>
              <div className="control has-icons-left">
                <input
                  type="text"
                  className="input"
                  placeholder="Email"
                  {...register("email")}
                />
                {errors.email && (
                  <p className="subtitle is-7 has-text-danger is-italic">
                    {errors.email?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="column">
            <label className="label font-antonio">Telefon*</label>
            <div className="control has-icons-left">
              <input
                type="text"
                className="input"
                placeholder="Numer telefonu"
                {...register("phone")}
              />
              {errors.phone && (
                <p className="subtitle is-7 has-text-danger is-italic">
                  {errors.phone?.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="columns ">
          <div className="column">
            <div className="field">
              <label className="label font-antonio">Miasto</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  className="input"
                  placeholder="Miasto"
                  {...register("city")}
                />
                {errors.city && (
                  <p className="subtitle is-7 has-text-danger is-italic">
                    {errors.city?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="field " style={{ textAlign: "left" }}>
          <input
            {...register("allAgreements")}
            type="checkbox"
            className="is-checkradio"
            id="allAgreements"
          />
          <label className="checkbox" htmlFor="allAgreements">
            <strong>Zaznacz wszystkie zgody</strong>
          </label>
        </div>
        <div className="field" style={{ textAlign: "left" }}>
          <input
            {...register("purchaseAgreements")}
            type="checkbox"
            className="is-checkradio"
            id="purchaseAgreements"
          />
          <label className="checkbox" htmlFor="purchaseAgreements">
            *Oświadczam, że zapoznałem/am się i akceptuję treść{" "}
            <Link to="/regulamin">Regulamin Konkursu</Link> oraz na
            przetwarzania danych zgodnie z{" "}
            <Link to="/polityka">Polityką Prywatności</Link>
          </label>
          {errors.purchaseAgreements && (
            <p className="subtitle is-7 has-text-danger is-italic">
              {errors.purchaseAgreements?.message}
            </p>
          )}
        </div>
        <div className="field" style={{ textAlign: "left" }}>
          <input
            {...register("newsletterAgreement")}
            type="checkbox"
            className="is-checkradio"
            id="newsletterAgreement"
          />
          <label className="checkbox" htmlFor="newsletterAgreement">
            Wyrażam zgodę na otrzymywanie informacji o nowych konkursach i
            usługach zgodnie z Polityką Prywatności
          </label>
        </div>
      </form>
      <div className="button-box-cart box">
        <button className="button is-primary is-large" onClick={openSummary}>
          Przejdź do płatności
        </button>
      </div>
      <Link to="/game">
        <button className="button">Wróć do mapy</button>
      </Link>
    </>
  );
};
