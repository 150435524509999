import React, { useEffect, createContext, useState, ReactNode } from "react";
import { MarkersPositions } from "../types/mapTypes";
import { PlayerDataTypes, GameContextValueTypes } from "../types/userDataTypes";
import { apiMongo } from "../api/apiData";
import axios from "axios";
import moment from "moment";
import "moment/locale/pl";
moment.locale("pl");

const GameContext = createContext<GameContextValueTypes | undefined>(undefined);

type GameProviderTypes = {
  children: ReactNode;
};

export const GameProvider: React.FC<GameProviderTypes> = ({ children }) => {
  const [coor, setCoor] = useState({ name: "", lat: 0, lng: 0 });
  const [gameSetup, setGameSetup] = useState({
    name: "",
    price: 0,
    startDate: "",
    endDate: "",
  });
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [newsletterSuccessModal, setNewsletterSuccessModal] = useState(false);

  const [markers, setMarkers] = useState<MarkersPositions[]>([]);
  const [playerData, setPlayerData] = useState<PlayerDataTypes>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    newsletterAgreement: false,
    purchaseAgreements: false,
  });
  const [cartValue, setCartValue] = useState(0);
  const [discountCode, setDiscountCode] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);

  const [payment, setPayment] = useState({
    method: "",
    isPaid: false,
  });

  const [winnerImages, setWinnerImages] = useState(null);
  const [ip, setIP] = useState("");

  // const [orderDetails, setOrderDetails] = useState({
  //   id: "",
  //   amount: cartValue,
  // })

  const getUserIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getUserIP();
  }, []);

  const getCoordinates = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/game/coordinates`)
      .then((response) => {
        setCoor({
          name: response.data.name,
          lat: response.data.lat,
          lng: response.data.lng,
        });
        setIsGameRunning(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("No game available at the moment");
          setIsGameRunning(false);
        } else {
          console.error(`Cannot get game coordinates: ${error.message}`);
        }
      });
  };

  const getGameSetup = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/game/setup`)
      .then((response) => {
        const { name, price, startDate, endDate } = response.data;
        setGameSetup({ name, price, startDate, endDate });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("No game available at the moment");
          setIsGameRunning(false);
        } else {
          console.error(`Cannot get game setup: ${error.message}`);
        }
      });
  };

  useEffect(() => {
    getCoordinates();
    getGameSetup();
    getWinnerImages();
  }, []);

  // USTAL i UPDATEuj wartość koszyka
  useEffect(() => {
    if (discountPercent > 0) {
      const value = markers.length * gameSetup.price;
      const discountedValue = value - value * (discountPercent / 100);
      const formattedCartValue = Number(discountedValue.toFixed(2));
      setCartValue(formattedCartValue);
    } else {
      setCartValue(Number((markers.length * gameSetup.price).toFixed(2)));
    }
  }, [markers, gameSetup.price, discountPercent]);

  const deleteLastMarker = () => {
    const updatedMarkers = [...markers];
    updatedMarkers.pop();
    setMarkers(updatedMarkers);
  };

  useEffect(() => {
    if (markers.length > 0) {
      if (markers) {
        const endGameDate = new Date(gameSetup.endDate);
        endGameDate.setDate(endGameDate.getDate());

        const dataToStore = {
          markers: markers,
          expirationDate: endGameDate.getTime(), // Store expiration date as a timestamp
        };

        sessionStorage.setItem("markers", JSON.stringify(dataToStore));
      }
    }

    //dicsount code
    if (discountPercent > 0) {
      const discountData = {
        code: discountCode,
        value: discountPercent,
        // expiration date set for 1 hour
        expirationDate: new Date(new Date().getTime() + 1 * 60 * 60 * 1000),
      };
      sessionStorage.setItem("discountData", JSON.stringify(discountData));
    }
  }, [markers, discountPercent, discountCode, gameSetup.endDate]);

  useEffect(() => {
    const sessionGameData = sessionStorage.getItem("markers");
    if (sessionGameData) {
      const gameData = JSON.parse(sessionGameData);
      const expirationDate = new Date(gameData.expirationDate);

      if (new Date() < expirationDate) {
        const markers = gameData.markers;
        setMarkers(markers);
      } else {
        sessionStorage.removeItem("markers");
      }
    }

    const sessionDiscountData = sessionStorage.getItem("discountData");
    if (sessionDiscountData) {
      const parsedSessionDiscountData = JSON.parse(sessionDiscountData);
      const expirationDate = new Date(parsedSessionDiscountData.expirationDate);
      if (new Date() < expirationDate) {
        setDiscountCode(parsedSessionDiscountData.code);
        setDiscountPercent(parsedSessionDiscountData.value);
      } else {
        sessionStorage.removeItem("discountData");
      }
    }
  }, []);

  const addMarkersToDatabase = async () => {
    try {
      const response = await apiMongo.post(
        `${process.env.REACT_APP_MONGO_URL}/game/markers`,
        { markers }
      );
      return response.data.responseMarkerId;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const addNewPlayer = async () => {
    try {
      const data = {
        ...playerData,
        creation_date: moment().format("LLL"),
      };

      const response = await apiMongo.post(
        `${process.env.REACT_APP_MONGO_URL}/game/players`,
        { data }
      );
      return response.data.userId;
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const addNewOrder = async () => {
    const playerID = await addNewPlayer();
    const markersID = await addMarkersToDatabase();

    if (playerID && markersID && cartValue > 0) {
      try {
        const order = {
          player_id: playerID,
          markers_id: markersID,
          game_name: coor.name,
          order_date: moment().format("LLL"),
          total_price: cartValue,
          payment_method: payment.method,
          purchaseAgreements: playerData.purchaseAgreements,
        };
        const response = await apiMongo.post(
          `${process.env.REACT_APP_MONGO_URL}/game/orders`,
          order
        );

        // setOrderDetails(response.data.order)

        return response.data.order;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const initializePayment = async (orderId: any) => {
    const paymentData = {
      //  "SEKRET" : "XXX,", - bedzie w .env node
      //     "KWOTA" : "1", - cartValue
      //     "NAZWA_USLUGI" : "MapGame gra",
      //     "ADRES_WWW" : "https://mapgame.pl/payment-confirmation?orderId=${orderId}`",
      //     "ID_ZAMOWIENIA" : "XXX", // orderId
      //     "EMAIL" : "", // playerData.email
      //     "DANE_OSOBOWE" : "", // playerData.firstName i playerData.lastname
      //     "TYP" : "INIT" // sprawdzić co to dokładnie
    };

    // Tworzenie ciągu dla funkcji SHA256
    // const hashString = `${hasloZustawien};${kwota};${nazwaUslugi};${adresWww};${idZamowienia};${sekret}`;

    // Generowanie HASH za pomocą SHA256
    // const hash = crypto.createHash('sha256').update(hashString).digest('hex');

    // Tworzenie URL-a do HotPay z wszystkimi parametrami
    // const hotPayUrl = `https://secure.hotpay.pl/?KWOTA=${kwota}&NAZWA_USLUGI=${encodeURIComponent(nazwaUslugi)}&ADRES_WWW=${encodeURIComponent(adresWww)}&ID_ZAMOWIENIA=${idZamowienia}&EMAIL=${encodeURIComponent(email)}&HASH=${hash}`;

    // return hotPayUrl;
  };

  const getWinnerImages = async () => {
    try {
      const response = await apiMongo.get(
        `${process.env.REACT_APP_MONGO_URL}/documents/images`
      );
      setWinnerImages(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const contextValue: GameContextValueTypes = {
    playerData,
    setPlayerData,
    markers,
    setMarkers,
    cartValue,
    setCartValue,
    deleteLastMarker,
    addMarkersToDatabase,
    addNewPlayer,
    addNewOrder,
    coor,
    gameSetup,
    isGameRunning,
    setIsGameRunning,
    isNewsletterModalOpen,
    setIsNewsletterModalOpen,
    newsletterSuccessModal,
    setNewsletterSuccessModal,
    discountCode,
    setDiscountCode,
    discountPercent,
    setDiscountPercent,
    payment,
    setPayment,
    winnerImages,
    setWinnerImages,
    ip,
    initializePayment,
    // orderDetails,
    // setOrderDetails,
  };

  return (
    <GameContext.Provider value={contextValue}>{children}</GameContext.Provider>
  );
};

export default GameContext;
